import background from './background'
import header     from './backgrounds/header-bg.png'
import icon       from './icon'
import logo       from './logo'
import profile    from './avatars/profile.jpg'
import texture    from './textures/project-paper.png'

import icons from './icons'

export default
{
    background,
    header,
    icon,
    icons,
    logo,
    profile,
    texture
} ;
