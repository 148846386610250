
import androidIcon192 from './icons/android-icon-192x192.png'

import appleIcon57  from './icons/apple-icon-57x57.png'
import appleIcon60  from './icons/apple-icon-60x60.png'
import appleIcon72  from './icons/apple-icon-72x72.png'
import appleIcon76  from './icons/apple-icon-76x76.png'
import appleIcon114 from './icons/apple-icon-114x114.png'
import appleIcon120 from './icons/apple-icon-120x120.png'
import appleIcon144 from './icons/apple-icon-144x144.png'
import appleIcon152 from './icons/apple-icon-152x152.png'
import appleIcon180 from './icons/apple-icon-180x180.png'

import favicon16 from './icons/favicon-16x16.png'
import favicon32 from './icons/favicon-32x32.png'
import favicon96 from './icons/favicon-96x96.png'

import msIcon144 from './icons/ms-icon-144x144.png'

import marker from './map/marker.svg'
import undo   from './map/undo.png'

export default
{
    androidIcon192,

    appleIcon57,
    appleIcon60,
    appleIcon72,
    appleIcon76,
    appleIcon114,
    appleIcon120,
    appleIcon144,
    appleIcon152,
    appleIcon180,

    favicon16,
    favicon32,
    favicon96,

    msIcon144,

    map :
    {
        marker,
        undo
    }
} ;
